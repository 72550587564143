<script>
import axios from "axios";
import moment from "moment/moment";
import Lottie from "@/components/widgets/lottie.vue";
import animationData from "@/components/widgets/lupuorrc.json";

export default {
  data() {
    return {
      order_type: 'customer',
      order_child_type: '',
      redirect_back_to_order_details_after_seconds: 5,
      activeStep: 1,
      order: null,
      formFields: [],
      loading: true,
      submitting: false,
      form: {
        contract: null,
        create_date: null,
        total_agreed_rate: 0,
        agreed_rate: 0
      },
      defaultOptions: {animationData: animationData},

      ready_document_path: ''
    }
  },
  components: {
    lottie: Lottie
  },
  computed: {
    order_number() {
      return this.$route.params.id
    },
    contracts() {
      if (this.order_type === 'customer') {
        return this.order.company.contracts.map(item => {
          return {
            value: item.id,
            label: item.name,
            currency: item.currency,
            start_date: item.start_date,
            end_date: item.expire_date,
          }
        })
      }
      try {
        const counterparty = this.order.counterparties.find(i => `counterparty_${i.id}` === this.order_type)
        if (!counterparty) return []
        return counterparty.company.contracts.map(item => {
          return {
            value: item.id,
            label: item.name,
            currency: item.currency,
            start_date: item.start_date,
            end_date: item.expire_date,
          }
        })
      } catch {
        return []
      }
    },
    selected_company_name() {
      if (this.order_type.startsWith('counterparty_')) {
        try {
          const counterparty_id = this.order_type.split('counterparty_')[1]
          const counterparty = this.order.counterparties.find(i => i.id === parseInt(counterparty_id))
          return counterparty.name || '**'
        } catch {
          return '**'
        }
      }
      return this.order.company.name
    },
    can_submit_form() {
      return this.form.contract && this.form.create_date && this.formFields.every(i => i.value && i.value !== "")
    },
    order_details_route_name() {
      if (this.order_child_type === 'container_order') {
        return 'orders_container_detail'
      } else if (this.order_child_type === 'wagon_order') {
        return 'orders_wagon_detail'
      } else if (this.order_child_type === 'wagon_empty_order') {
        return 'orders_empty_wagon_detail'
      }
      return 'order_container_list'
    }
  },
  methods: {
    async getOrder() {
      try {
        this.loading = true
        let response = await axios.get(`/order/order_detail/${this.$route.params.id}/`)
        this.order = response.data
        this.setUpFields()
        this.loading = false
      } catch {
        alert("error")
      }
    },
    async getTerritory() {
      let response = await axios.get(`/core/territories/`)
      const territories = response.data.results.map(t => {
        return {
          value: t.id,
          label: t.name
        }
      })
      let forwarding_territories = this.formFields.find(i => i.field === 'forwarding_territories' && i.type === 'select')
      let transit_point = this.formFields.find(i => i.field === 'transit_point' && i.type === 'select')

      if (forwarding_territories) {
        forwarding_territories.options = territories
      }
      if (transit_point) {
        transit_point.options = territories
      }
    },
    setUpFields() {
      if (!this.order) return;
      this.formFields = [
        {
          field: "client",
          label: "Клиент/Client",
          type: "text",
          placeholder: "Enter client name",
          value: this.order.company ? this.order.company.name : '',
          disabled: true
        },
        {
          field: "shipper",
          label: "Грузоотправитель/Shipper",
          type: "text",
          placeholder: "Enter shipper name",
          value: this.order.shipper,
          disabled: true
        },
        {
          field: "consignee",
          label: "Грузополучатель/Consignee",
          type: "text",
          placeholder: "Enter consignee name",
          value: this.order.consignee,
          disabled: true
        },
        {
          field: "place_of_loading",
          label: "Место погрузки/Place of loading",
          description: "(полное наименование станции, код/full name of station, code)",
          type: "text",
          placeholder: "Enter place of loading",
          value: `${this.order.departure} (${this.order.departure_code})`,
        },
        {
          field: "final_destination",
          label: "Место назначения/Final destination",
          description: "(полное наименование станции, код/full name of station, code)",
          type: "text",
          placeholder: "Enter final destination",
          value: `${this.order.destination} (${this.order.destination_code})`,
        },
        {
          field: "forwarding_territories",
          label: "Экспедируемая территория/Forwarding territories",
          description: "(Казахстан, СНГ, третьи страны)",
          type: "text",
          placeholder: "Enter forwarding territory",
          value: "",
        },
        {
          field: "transit_point",
          label: "Пункт перехода",
          description: "(Казахстан, СНГ, третьи страны)",
          type: "text",
          placeholder: "Select transit point",
          value: this.order.border_crossing,
          disabled: true
        },
        {
          field: "cargo_description",
          label: "Наименование груза/Description of cargo",
          type: "text",
          placeholder: "Enter cargo description",
          value: this.order.product ? this.order.product.name : '',
          disabled: true
        },
        {
          field: "cargo_code",
          label: "Код по ГНГ/ЕТСНГ/HS code of cargo",
          type: "text",
          placeholder: "Enter HS code of cargo",
          value: this.order.product
              ? `${this.order.product.hc_code} / ${this.order.product.etcng_code}`
              : '-',
          disabled: true
        },
        {
          field: "loading_date",
          label: "Дата загрузки/Date of loading",
          type: "date",
          placeholder: "Enter loading date",
          value: "",
        },
        {
          field: "transit_time",
          label: "Срок поставки/Transit time",
          type: "text",
          placeholder: "Enter transit time in days",
          value: "",
        },
        {
          field: "loading_terms",
          label: "Условия поставки/Terms of loading",
          type: "text",
          placeholder: "Enter loading terms",
          value: this.order.conditions_of_carriage,
          disabled: true
        },
        {
          field: "insurance",
          label: "Страховка груза/Insurance",
          type: "text",
          placeholder: "Enter insurance details",
          value: "",
        },
        {
          field: "shipment_type",
          label: "Род подвижного состава/Shipment type",
          description: "(универсальный, специализированный контейнер/вагон)",
          type: "text",
          placeholder: "Select shipment type",
          value: this.order.rolling_stock,
          disabled: true
        },
        {
          field: "vehicle_type_quantity",
          label: "Тип и кол-во транспортного средства/Q-ty of vehicles",
          description: "(контейнер/вагон/авто/авиа)",
          type: "number",
          placeholder: "Enter type and quantity of vehicles",
          value: this.order.quantity,
          disabled: true
        },
        {
          field: "agreed_rate",
          label: "Согласованная ставка/Agreed rate",
          type: "number",
          min: 0,
          placeholder: "Enter agreed rate",
          value: this.form.agreed_rate,
          disabled: true
        },
        {
          field: "total_amount",
          label: "Итоговая сумма/Total amount",
          type: "number",
          min: 0,
          placeholder: "Enter total amount",
          value: this.form.total_agreed_rate,
          disabled: true
        },
        {
          field: "payment_terms",
          label: "Условия оплаты/Term of payment",
          type: "text",
          placeholder: "Enter payment terms",
          value: "",
        },
      ]
    },
    formatDate(data) {
      if (!data) return '-'
      return moment(data).format('lll')
    },
    async submitForm() {
      if (this.can_submit_form) {
        try {
          let shipper = this.formFields.find(i => i.field === 'shipper')
          let consignee = this.formFields.find(i => i.field === 'consignee')
          let place_of_loading = this.formFields.find(i => i.field === 'place_of_loading')
          let final_destination = this.formFields.find(i => i.field === 'final_destination')
          let forwarding_territories = this.formFields.find(i => i.field === 'forwarding_territories')
          let transit_point = this.formFields.find(i => i.field === 'transit_point')
          let loading_date = this.formFields.find(i => i.field === 'loading_date')
          let transit_time = this.formFields.find(i => i.field === 'transit_time')
          let loading_terms = this.formFields.find(i => i.field === 'loading_terms')
          let insurance = this.formFields.find(i => i.field === 'insurance')
          let shipment_type = this.formFields.find(i => i.field === 'shipment_type')
          let payment_terms = this.formFields.find(i => i.field === 'payment_terms')

          this.submitting = true

          let response = await axios.post('/order/booking_order/create/', {
            date: this.form.create_date,
            order_number: this.order_number,
            contract_id: this.form.contract.value,
            shipper: shipper.value,
            consignee: consignee.value,
            departure: place_of_loading.value,
            destination: final_destination.value,
            territories: forwarding_territories.value,
            border_crossing: transit_point.value,
            cargo_name: this.order.product.name,
            cargo_hs_code: this.order.product.hc_code,
            cargo_etcng: this.order.product.etcng_code,
            loading_date: loading_date.value,
            transit_time: transit_time.value,
            terms_of_loading: loading_terms.value,
            insurance: insurance.value,
            shipment_type: shipment_type.value,
            quantity: this.order.quantity,
            agreed_rate: this.form.agreed_rate,
            total_agreed_rate: this.form.total_agreed_rate,
            term_of_payment: payment_terms.value,
            booking_type: this.order_type === 'customer' ? 'customer' : 'agent',
          })
          this.ready_document_path = response.data.file
          this.submitting = false
          this.activeStep = 4

          this.redirect_back_to_order_details_after_seconds = 5;

          const countdownTimer = setInterval(() => {
            if (this.redirect_back_to_order_details_after_seconds > 0) {
              this.redirect_back_to_order_details_after_seconds--;
            } else {
              clearInterval(countdownTimer);
              this.$router.push({
                name: this.order_details_route_name,
                params: {
                  id: this.$route.params.id,
                }
              });
            }
          }, 1000);

        } catch (error) {
          console.log(error)
          this.submitting = false
          alert("error")
        }
      } else {
        alert("Please, fill in the form")
      }
    },
    downloadFile() {
      if (this.ready_document_path) {
        let url = process.env.VUE_APP_ORDER_URL + this.ready_document_path;
        fetch(url)
            .then((resp) => resp.blob())
            .then((blobobject) => {
              const blob = window.URL.createObjectURL(blobobject);
              const anchor = document.createElement("a");
              anchor.style.display = "none";
              anchor.href = blob;
              anchor.download = url.toString().split("/")[
              url.toString().split("/").length - 1
                  ];
              document.body.appendChild(anchor);
              anchor.click();
              window.URL.revokeObjectURL(blob);
            })
            .catch(() => alert("An error in downloading the file sorry"));
      } else {
        alert("No document found")
      }
    },
    goStep2() {
      if (this.order_type.startsWith('counterparty_')) {
        const counterparty_id = this.order_type.split('_')[1]
        const counterparty = this.order.counterparties.find(i => i.id === parseInt(counterparty_id));
        this.form.agreed_rate = counterparty ? counterparty.company.agreed_rate || 0 : 0
        this.form.total_agreed_rate = counterparty ? counterparty.company.total_agreed_rate || 0 : 0
      } else {
        this.form.agreed_rate = this.order.agreed_rate || 0
        this.form.total_agreed_rate = this.order.total_agreed_rate || 0
      }
      this.setUpFields()
      this.activeStep = 2
    }
  },
  watch: {
    activeStep(newValue) {
      this.$router.push({
        query: {
          ...this.$route.query,
          activeStep: newValue,
        }
      })
    },
    "$route.query": {
      handler(newValue) {
        if (newValue) {
          if (newValue.activeStep === '1') {
            this.form.contract = null
            this.activeStep = 1
          } else if (newValue.activeStep === '2') {
            this.activeStep = 2
          } else if (newValue.activeStep === '3') {
            if (this.form.contract) {
              this.activeStep = 3
            } else {
              this.$router.push({
                query: {
                  ...this.$route.query,
                  activeStep: 1,
                }
              })
            }
          } else if (newValue.activeStep === '4') {
            if (!this.ready_document_path) {
              this.$router.push({
                query: {
                  ...this.$route.query,
                  activeStep: 1,
                }
              })
            }
          }
        }
      },
      immediate: true,
    }
  },
  async mounted() {
    await this.getOrder()
    await this.getTerritory()
  }
}
</script>

<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title class="mb-0">Create a Booking-Order</b-card-title>
    </b-card-header>
    <b-card-body>

      <div class="d-flex align-items-center justify-content-center" style="min-height: 70vh;">
        <b-spinner v-if="loading" variant="success"></b-spinner>
        <template v-else>

          <Transition name="fade" mode="out-in">
            <div v-if="activeStep === 1" class="text-center mb-4 pb-2">
              <h4 class="fw-semibold fs-22"> Choose the booking-order type </h4>
              <p class="text-muted mb-5 fs-15">
                Make sure to choose the right one
              </p>

              <b-list-group size="lg" class="text-start mb-4">
                <BListGroupItem :variant="order_type === 'customer' ? 'success' : undefined">
                  <div class="form-check form-radio-success">
                    <input v-model="order_type" class="form-check-input" type="radio" id="order_type_customer"
                           value="customer"
                    >
                    <label class="form-check-label" for="order_type_customer">
                      {{ order.company.name }}
                    </label>
                  </div>
                </BListGroupItem>
              </b-list-group>
              <div class="text-start mb-2 fs-14 text-muted">
                Order Counterparties:
              </div>
              <b-list-group class="mb-5">
                <BListGroupItem v-for="counterparty in order.counterparties" :key="`counterparty_${counterparty.id}`"
                                tag="label"
                                :variant="order_type === `counterparty_${counterparty.id}` ? 'success' : undefined"
                >
                  <div class="form-check form-radio-success">
                    <input v-model="order_type" class="form-check-input" type="radio"
                           :id="`order_type_counterparty_${counterparty.io}`"
                           :value="`counterparty_${counterparty.id}`"
                    >
                    <label class="form-check-label" :for="`order_type_counterparty_${counterparty.io}`">
                      {{ counterparty.name }}
                    </label>
                  </div>
                </BListGroupItem>
              </b-list-group>

              <b-button @click="goStep2()" variant="outline-success" size="lg">
                Next
                <i class="mdi mdi-arrow-right ms-1"></i>
              </b-button>
            </div>
            <div v-else-if="activeStep === 2" class="text-center mb-4 pb-2">
              <h4 class="fw-semibold fs-22"> Choose the contract to continue </h4>
              <p class="text-muted mb-5 fs-15">
                Make sure to choose the right one
              </p>


              <div data-simplebar style="max-height: 415px;" class="mb-5">
                <b-list-group>
                  <BListGroupItem v-for="contract in contracts" :key="`contract_${contract.id}`"
                                  class="list-group-item cursor-pointer"
                                  @click="(form.contract || {}).value === contract.value ? form.contract = null : form.contract = contract"
                                  :variant="(form.contract || {}).value === contract.value ? 'success' : 'soft-success'"
                  >
                    <div class="d-flex align-items-center">
                      <div class="flex-grow-1">
                        <div class="d-flex align-items-center gap-2">
                          <div class="flex-shrink-0 avatar-sm">
                            <div class="avatar-title bg-soft-secondary text-secondary rounded">
                              <i class="mdi mdi-file fs-2"></i>
                            </div>
                          </div>
                          <div class="flex-shrink-0 ms-2 text-start">
                            <h5 class="mb-1">{{ contract.label }}</h5>
                            <span>
                              {{ contract.start_date }} to {{ contract.end_date }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="flex-shrink-0">
                        <span class="fs-4" :class="{
                              'text-success fw-medium': (form.contract || {}).value === contract.value,
                              'text-muted': (form.contract || {}).value !== contract.value,
                            }">
                          {{ contract.currency }}
                        </span>
                      </div>
                    </div>
                  </BListGroupItem>
                </b-list-group>
              </div>

              <div class="d-flex gap-4 justify-content-between">
                <b-button @click="activeStep = 1" variant="light" size="lg">
                  <i class="mdi mdi-arrow-left me-1"></i>
                  Order type
                </b-button>
                <b-button @click="activeStep = 3" :disabled="!form.contract" variant="outline-success" size="lg">
                  Next
                  <i class="mdi mdi-arrow-right ms-1"></i>
                </b-button>
              </div>
            </div>
            <div v-else-if="activeStep === 3" class="vertical-navs-step">
              <b-row v-if="form.contract" class="gy-2 align-items-center justify-content-center">

                <b-col lg="12">
                  <p class="text-center mx-auto lh-lg fs-5" style="max-width: 750px">
                    <b>Транспортная заявка/ Booking order № __ от/dd
                      {{ form.create_date ? form.create_date : '_______' }} г.
                      По контракту/According to
                      the contract № {{ form.contract.label }}
                      от {{ form.contract.start_date }} г. с компании/with
                      {{ selected_company_name }}
                    </b>
                    <br>На экспедирование груза/Forwarding
                  </p>
                </b-col>


                <b-col lg="4" class="mb-4">
                  <div class="d-flex justify-content-center">
                    <input v-model="form.create_date" type="date" class="form-control">
                  </div>
                </b-col>

                <b-col lg="12">
                  <form @submit.prevent="submitForm" id="form" :class="{
                    'loading': submitting,
                  }">
                    <div>
                      <table class="table table-bordered table-nowrap">
                        <tbody>
                        <template v-for="(field, index) in formFields" :key="`form_field_${field.field}`">
                          <tr class="align-middle">
                            <th scope="row" class="text-center">{{ index + 1 }}</th>
                            <th style="max-width: 150px" class="text-truncate">
                              <span class="text-truncate">{{ field.label }}</span>
                              <p v-if="field.description" class="mb-0 fw-normal text-muted text-truncate">{{
                                  field.description
                                }}</p>
                            </th>
                            <td>
                              <select v-if="field.type === 'select'"
                                      v-model="field.value"
                                      class="form-select"
                              >
                                <option value="" selected disabled>{{ field.placeholder }}</option>
                                <option v-for="option in field.options"
                                        :key="`field_${field.value}_option_${option}`"
                                        :value="option.value"
                                >
                                  {{ option.label }}
                                </option>
                              </select>
                              <input v-else :type="field.type"
                                     v-model="field.value"
                                     :placeholder="field.placeholder"
                                     :disabled="field.disabled"
                                     class="form-control"
                                     required
                              >
                            </td>
                          </tr>
                        </template>
                        </tbody>
                      </table>
                    </div>
                    <div class="d-flex gap-4 justify-content-between">
                      <b-button @click="activeStep = 2" variant="light" size="lg">
                        <i class="mdi mdi-arrow-left me-1"></i>
                        Contract
                      </b-button>
                      <b-button type="submit" variant="success" size="lg">
                        Submit
                        <i class="mdi mdi-check ms-1"></i>
                      </b-button>
                    </div>
                  </form>
                </b-col>
              </b-row>

            </div>
            <div v-else-if="activeStep === 4" class="text-center">
              <div class="mb-4">
                <lottie colors="primary:#0ab39c,secondary:#405189"
                        :options="defaultOptions" :height="180" :width="180"/>
              </div>
              <h2 class="mb-3">
                {{ redirect_back_to_order_details_after_seconds }} secs
              </h2>
              <p class="text-muted fs-16 mb-4">
                Redirecting back to the details page
              </p>
              <b-button @click="downloadFile()" size="lg">
                Download Document
                <i class="mdi mdi-download ms-2"></i>
              </b-button>
            </div>
          </Transition>

        </template>
      </div>
    </b-card-body>
  </b-card>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (min-width: 1400px) {
  .vertical-navs-step {
    min-width: 1100px; /* Default min-width */
    max-width: 1100px; /* Default min-width */
  }
}

#form {
  position: relative;
}

#form.loading::before {
  content: "Loading...";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: white;
  font-weight: bold;
  font-size: 22px;
}
</style>